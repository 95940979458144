import React from "react"
import { Link } from "gatsby"

import kebabCase from 'lodash/kebabCase';

const PostLink = ({ post }) => {
    const tagsData = post.frontmatter.tags ? post.frontmatter.tags : [];
    const tagsLength = tagsData.length - 1;
    const tags = tagsData.map((tag, index) => {
        return (
            <>
                <Link key={`${post.id}-${tag}`} to={`/tags/${kebabCase(tag)}/`} className="text-green-300 inline-flex items-center mt-4">
                    {tag}
                </Link>
                {index < tagsLength ? ', ' : ''}
            </>
        );
    });

    return (
        <div className="py-8 flex flex-wrap md:flex-nowrap">
            <div className="md:flex-grow">
                <span className="text-gray-500 text-sm">{post.frontmatter.date}</span>
                <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">{post.frontmatter.title}</h2>
                <p className="leading-relaxed">{post.frontmatter.description}</p>
                <div class="flex justify-between items-center mt-4">
                    <Link to={post.frontmatter.slug} className="text-green-500 inline-flex items-center mt-4">Learn More
                    <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M5 12h14"></path>
                            <path d="M12 5l7 7-7 7"></path>
                        </svg>
                    </Link>

                    { tags.length > 0 && <span>Tags:{' '} {tags} </span> }
                </div>
            </div>
        </div>
    );
}

export default PostLink
