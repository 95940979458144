import React from 'react';
import PostLink from './PostLink';

class PostList extends React.Component {

    render() {
        const emptyMessage = 'Sin resultados';
        const edges = this.props.edges ? this.props.edges : [];
        const Posts = edges
            .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
            .map((edge) => <PostLink key={edge.node.id} post={edge.node}/>);
        return (
            <section className="text-gray-600 body-font overflow-hidden">
                <div className="container px-5 py-6 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                                {this.props.title}
                            </h1>
                        </div>
                        <div className="-my-8 divide-y-2 divide-gray-100">
                            {edges ? Posts : emptyMessage}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PostList;
