import * as React from 'react';
import { graphql } from 'gatsby';
import PostList from '../components/post/PostList';
import Footer from '../components/footer';
import Header from '../components/header';

// markup
const IndexPage = ({ data }) => {
    const edges = data ? data.allMarkdownRemark.edges : [];

    return (
        <main>
            <Header />
            <section className="text-gray-600 body-font">
                <div className="container px-5 lg:py-12 sm:py-0 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="flex flex-col sm:flex-row md:mt-10 ">
                            <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                                <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400 hidden">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="w-10 h-10"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </div>
                                <div className="flex flex-col items-center text-center justify-center">
                                    <h2 className="font-medium title-font lg:mt-16 sm:mt-4 text-gray-900 text-lg">
                                        Carlos Aguilera
                                    </h2>
                                    <div className="w-12 h-1 bg-green-500 rounded mt-2 mb-4"></div>
                                </div>
                            </div>
                            <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                                <p className="leading-relaxed text-lg mb-4">
                                    I'm a seasoned Software Engineer with +10
                                    years' experience developing, and managing
                                    products for HHRR, and recently for FinTech
                                    industry. I'm passionate about data
                                    structures, algorithms, software
                                    architecture, system design, and I'm
                                    currently applying this knowledge in my role
                                    as Software Engineer for companies and
                                    clients that I work with. I'm currently
                                    seeking collaborate in new projects, so if
                                    you'd like to work together, contact me by{' '}
                                    <a
                                        className="text-green-500"
                                        href="mailto:a.carlosing@gmail.com"
                                    >
                                        email
                                    </a>{' '}
                                    or{' '}
                                    <a
                                        className="text-green-500"
                                        href="tel:+528113002226"
                                    >
                                        phone call
                                    </a>
                                    .
                                    {/* I'm passionate about [What results you achieve] for [Companies, partners, or clients], and I've spent the last 8 years working growing [Company's] [KPI] by [% growth] through dedicated efforts [Skill], [Another Skill], and [Skill]. */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PostList title={'Latest Posts'} edges={edges} />
            <Footer />
        </main>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 3
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        slug
                        title
                        tags
                        description
                        category
                    }
                }
            }
        }
    }
`;
